import React from 'react'

const Contact = (props) => (
    <section id="contact">
        <div className="inner">

        </div>
    </section>
)

export default Contact
